<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title
        class="body-1"
        v-if="navigete != null && navigete != '' && navigete != 'null'"
      >
        การเงิน <v-icon>mdi-chevron-right</v-icon>
        สร้างใบแจ้งหนี้
      </v-card-title>
    </v-card>

    <v-card class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon size="20" color="primary">fa-sliders-h</v-icon
        ><span class="my-3 ml-3">ค้นหารายการ</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div>
          <v-row>
            <v-col cols="12" md="2" lg="1" class="pb-2">
              <span><b> เลือกวันที่ : </b></span>
            </v-col>
            <v-col cols="12" md="3" lg="2" class="pb-2">
              <v-menu
                ref="menuStartDate"
                v-model="menuStartDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateStartTextShow"
                    outlined
                    readonly
                    label="เริ่มต้น"
                    prepend-inner-icon="mdi-calendar"
                    @blur="startDate = parseDate(dateStartTextShow)"
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    clearable
                    @click:clear="(dateStartTextShow = null), (startDate = null)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @input="menuStartDate = false"
                  @change="dateStartTextShow = formatDate(startDate)"
                  no-title
                  :allowed-dates="allowedDates"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3" lg="2" class="pb-2">
              <v-menu
                ref="menuEndDate"
                v-model="menuEndDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateEndTextShow"
                    outlined
                    readonly
                    label="สิ้นสุด"
                    prepend-inner-icon="mdi-calendar"
                    @blur="endDate = parseDate(dateEndTextShow)"
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    clearable
                    @click:clear="(dateEndTextShow = null), (endDate = null)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @input="menuEndDate = false"
                  @change="dateEndTextShow = formatDate(endDate)"
                  no-title
                  :allowed-dates="allowedDates"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="1" class="pb-2">
              <span><b> เลือกร้านค้า : </b></span>
            </v-col>
            <v-col cols="12" md="6" lg="4" class="pb-2" style="display: flex">
              <v-autocomplete
                v-model="search_shops"
                :items="shop.list"
                :item-text="(item) => item.Name"
                :item-value="(item) => item.ID"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="1" class="pb-2">
              <span><b> เลขอ้างอิง : </b></span>
            </v-col>
            <v-col cols="12" md="6" lg="4" class="pb-2" style="display: flex">
              <v-text-field
                outlined
                :value="search_groupName"
                @change="(value) => (search_groupName = value)"
                autocomplete="off"
                placeholder="กรอกเลขอ้างอิง"
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2" lg="1" class="pb-2">
              <span><b> สถานะ : </b></span>
            </v-col>
            <v-col cols="12" md="6" lg="4" class="pb-2" style="display: flex">
              <v-radio-group v-model="rd_status" row>
                <v-radio label="ทั้งหมด" value="null"></v-radio>
                <v-radio label="ออกใบแจ้งหนี้แล้ว" value="1"></v-radio>
                <v-radio label="ยังไม่ออกใบแจ้งหนี้" value="2"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2" lg="1" class="pb-2"> </v-col>
            <v-col cols="12" md="6" lg="4" class="pb-2">
              <v-btn
                dark
                color="error"
                @click="(selected = []), getInvoice(PageLimits, 0, 1)"
                class="fn-14"
                ><v-icon>mdi-magnify</v-icon>ค้นหา</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>
        <v-icon size="20" color="success">fa-receipt</v-icon
        ><span class="my-3 ml-3">รายการสรุปยอดรายวัน</span>
        <v-spacer />
        <v-btn
          v-if="selected.length > 0"
          class="ma-1"
          color="danger"
          dark
          @click="removeInvoice(null)"
          >ลบรายการ</v-btn
        >
        <v-btn
          v-if="selected.length > 0 && rd_status_tmp != 1"
          class="ma-1"
          color="primary"
          @click="confirmCreateInvoice()"
          >สร้างใบแจ้งหนี้</v-btn
        >
        <v-btn v-else-if="rd_status_tmp != 1" class="ma-1">สร้างใบแจ้งหนี้</v-btn>
        <!-- <v-btn v-show="invoice.table.length != 0" color="success" outlined @click="Export_to_excel_data_invoice()">Export to Excel</v-btn> -->
        <!-- <v-btn v-show="invoice.table.length != 0" size="1" color="success" dark class="mr-1"><v-icon >mdi-file-excel</v-icon>
            <vue-excel-xlsx
                :data="invoice.table"
                :columns="excel_invoice_list_header"
                :filename="'รายงานใบแจ้งหนี้'"
                :sheetname="'รายงานใบแจ้งหนี้'"
                >
                Export to Excel
            </vue-excel-xlsx>
          </v-btn> -->
      </v-card-title>
      <v-divider />
      <v-card-text>
        <span>เลือกทั้งหมด {{ selected.length }}/{{ resultCount }} </span>
        <div v-if="rd_status_tmp != 1">
          <v-row class="mt-2 mx-0">
            <v-col cols="12" class="px-0">
              <!-- PC -->
              <v-data-table
                v-model="selected"
                show-select
                :headers="invoice.headers"
                :key="invoice.key_table"
                :items="invoice.table"
                :items-per-page="9999999999"
                item-key="id"
                class="elevation-0 packhai-border-table"
                hide-default-footer
                :mobile-breakpoint="0"
              >
                <template v-slot:item.num="{ item }">
                  {{ item.no }}
                </template>

                <template v-slot:item.createdDatetime="{ item }">
                  {{ set_format_date_time(item.createdDatetime) }}
                </template>

                <template v-slot:item.deliveryPrice="{ item }">
                  {{ format_price(item.deliveryPrice) }}
                </template>
                <template v-slot:item.remotePrice="{ item }">
                  {{ format_price(item.remotePrice) }}
                </template>
                <template v-slot:item.codPrice="{ item }">
                  {{ format_price(item.codPrice) }}
                </template>
                <template v-slot:item.codVatAmount="{ item }">
                  {{ format_price(item.codVatAmount) }}
                </template>
                <template v-slot:item.codVatPercent="{ item }">
                  {{ format_price(item.codVatPercent) }}
                </template>
                <template v-slot:item.packPrice="{ item }">
                  {{ format_price(item.packPrice) }}
                </template>
                <template v-slot:item.boxPrice="{ item }">
                  {{ format_price(item.boxPrice) }}
                </template>
                <template v-slot:item.bubblePrice="{ item }">
                  {{ format_price(item.bubblePrice) }}
                </template>
                <template v-slot:item.vrichFee="{ item }">
                  {{ format_price(item.vrichFee) }}
                </template>
                <template v-slot:item.serviceVatAmount="{ item }">
                  {{ format_price(item.serviceVatAmount) }}
                </template>
                <template v-slot:item.serviceVatPercent="{ item }">
                  {{ format_price(item.serviceVatPercent) }}
                </template>
                <template v-slot:item.otherServicePrice="{ item }">
                  {{ format_price(item.otherServicePrice) }}
                </template>
                <template v-slot:item.discount="{ item }">
                  {{ format_price(item.discount) }}
                </template>
                <template v-slot:item.totalPriceIncludeVat="{ item }">
                  {{ format_price(item.totalPriceIncludeVat) }}
                </template>

                <template v-slot:item.codVatType="{ item }">
                  {{ format_vat_type(item.codVatType) }}
                </template>

                <template v-slot:item.serviceVatType="{ item }">
                  {{ format_vat_type(item.serviceVatType) }}
                </template>
                <template v-slot:item.CancelInvoice="{ item }">
                  <v-btn @click="removeInvoice(item)" icon color="danger"
                    ><v-icon>fa-trash-alt</v-icon></v-btn
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row class="mt-2 mx-0">
            <v-col cols="12" class="px-0">
              <!-- PC -->
              <v-data-table
                :headers="headers_group_name_not_null"
                :key="invoice.key_table"
                :items="invoice.table"
                :items-per-page="9999999999"
                item-key="id"
                class="elevation-0 packhai-border-table"
                :mobile-breakpoint="0"
                hide-default-footer
              >
                <template v-slot:item.num="{ item }">
                  {{ item.no }}
                </template>

                <template v-slot:item.createdDatetime="{ item }">
                  {{ set_format_date_time(item.createdDatetime) }}
                </template>

                <template v-slot:item.deliveryPrice="{ item }">
                  {{ format_price(item.deliveryPrice) }}
                </template>
                <template v-slot:item.remotePrice="{ item }">
                  {{ format_price(item.remotePrice) }}
                </template>
                <template v-slot:item.codPrice="{ item }">
                  {{ format_price(item.codPrice) }}
                </template>
                <template v-slot:item.codVatAmount="{ item }">
                  {{ format_price(item.codVatAmount) }}
                </template>
                <template v-slot:item.codVatPercent="{ item }">
                  {{ format_price(item.codVatPercent) }}
                </template>
                <template v-slot:item.packPrice="{ item }">
                  {{ format_price(item.packPrice) }}
                </template>
                <template v-slot:item.boxPrice="{ item }">
                  {{ format_price(item.boxPrice) }}
                </template>
                <template v-slot:item.bubblePrice="{ item }">
                  {{ format_price(item.bubblePrice) }}
                </template>
                <template v-slot:item.vrichFee="{ item }">
                  {{ format_price(item.vrichFee) }}
                </template>
                <template v-slot:item.serviceVatAmount="{ item }">
                  {{ format_price(item.serviceVatAmount) }}
                </template>
                <template v-slot:item.serviceVatPercent="{ item }">
                  {{ format_price(item.serviceVatPercent) }}
                </template>
                <template v-slot:item.otherServicePrice="{ item }">
                  {{ format_price(item.otherServicePrice) }}
                </template>
                <template v-slot:item.discount="{ item }">
                  {{ format_price(item.discount) }}
                </template>
                <template v-slot:item.totalPriceIncludeVat="{ item }">
                  {{ format_price(item.totalPriceIncludeVat + item.otherServicePrice) }}
                </template>

                <template v-slot:item.codVatType="{ item }">
                  {{ format_vat_type(item.codVatType) }}
                </template>

                <template v-slot:item.serviceVatType="{ item }">
                  {{ format_vat_type(item.serviceVatType) }}
                </template>
                <template v-slot:item.CancelInvoice="{ item }">
                  <v-btn @click="removeInvoice(item)" icon color="danger"
                    ><v-icon>fa-trash-alt</v-icon></v-btn
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>

        <v-row class="mt-2">
          <v-col cols="2" lg="1"> </v-col>
          <v-col cols="8" lg="10" @click="changePage()">
            <v-pagination
              v-if="pageLength > 1"
              class="text-center pa-2 ma-0"
              v-model="page"
              :length="pageLength"
              :total-visible="pageTotalVisible"
              color="info"
            ></v-pagination>
          </v-col>
          <v-col cols="2" class="text-right" lg="1">
            <v-select
              v-model="PageLimits"
              label="จำนวนแสดง"
              :items="[10, 20, 50, 100, 500, 1000]"
              @change="LimitChange()"
              outlined
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="confirmCreateInvoiceDialog" width="500">
      <v-card elevation="1">
        <v-card-title class="pr-1">
          ยืนยันการสร้างใบแจ้งหนี้
          <v-spacer />
          <v-btn icon @click="confirmCreateInvoiceDialog = false"
            ><v-icon class="fn-25">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="pl-7 pb-0" v-if="data_uninvoice_summary_dict != null">
          <!-- <v-row class="py-1">
            <v-col cols="6">
              <strong>จำนวนออเดอร์</strong> :
            </v-col>
            <v-col cols="6" align="right">
              <span>{{ data_uninvoice_summary_dict.countOrder }}</span>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>วันที่</strong> :
            </v-col>
            <v-col cols="6" align="right">
              <span>{{ data_uninvoice_summary_dict.date }}</span>
            </v-col>
          </v-row> -->
          <v-row class="py-1">
            <v-col cols="6"> <strong>ชื่อร้าน</strong> : </v-col>
            <v-col cols="6" align="right">
              <span v-if="selected.length > 0">{{ selected[0].shopName }}</span>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6"> <strong>ค่าส่ง</strong> : </v-col>
            <v-col cols="6" align="right"
              ><span>{{
                formatMoney(data_uninvoice_summary_dict.deliveryPrice)
              }}</span></v-col
            >
          </v-row>
          <v-row class="py-1">
            <v-col cols="6"> <strong>ค่าพื้นที่ห่างไกล</strong> : </v-col>
            <v-col cols="6" align="right"
              ><span>{{
                formatMoney(data_uninvoice_summary_dict.remotePrice)
              }}</span></v-col
            >
          </v-row>
          <v-row class="py-1">
            <v-col cols="6"> <strong>ค่าCOD</strong> : </v-col>
            <v-col cols="6" align="right"
              ><span>{{ formatMoney(data_uninvoice_summary_dict.codPrice) }}</span></v-col
            >
          </v-row>
          <v-row class="py-1">
            <v-col cols="6"> <strong>ค่ากล่อง</strong> : </v-col>
            <v-col cols="6" align="right"
              ><span>{{ formatMoney(data_uninvoice_summary_dict.boxPrice) }}</span></v-col
            >
          </v-row>
          <v-row class="py-1">
            <v-col cols="6"> <strong>ค่าแพ็ค</strong> : </v-col>
            <v-col cols="6" align="right"
              ><span>{{
                formatMoney(data_uninvoice_summary_dict.packPrice)
              }}</span></v-col
            >
          </v-row>
          <v-row class="py-1">
            <v-col cols="6"> <strong>ค่าบับเบิล</strong> : </v-col>
            <v-col cols="6" align="right"
              ><span>{{
                formatMoney(data_uninvoice_summary_dict.bubblePrice)
              }}</span></v-col
            >
          </v-row>
          <v-row class="py-1">
            <v-col cols="6"> <strong>vrichFee</strong> : </v-col>
            <v-col cols="6" align="right"
              ><span>{{ formatMoney(data_uninvoice_summary_dict.vrichFee) }}</span></v-col
            >
          </v-row>
          <v-row class="py-1">
            <v-col cols="6"> <strong>ส่วนลด</strong> : </v-col>
            <v-col cols="6" align="right"
              ><span>{{ formatMoney(data_uninvoice_summary_dict.discount) }}</span></v-col
            >
          </v-row>
          <v-row class="py-1">
            <v-col cols="6"> <strong>ค่าใช้จ่ายอื่นๆ</strong> : </v-col>
            <v-col cols="6" align="right"
              ><span>{{
                formatMoney(data_uninvoice_summary_dict.otherServicePrice)
              }}</span></v-col
            >
          </v-row>
          <v-row class="py-1">
            <v-col cols="6"> <strong>ราคารวม</strong> : </v-col>
            <v-col cols="6" align="right"
              ><span>{{
                formatMoney(data_uninvoice_summary_dict.totalPrice)
              }}</span></v-col
            >
          </v-row>

          <v-row class="py-1">
            <v-col cols="6"> <strong>ประเภทภาษีค่า COD</strong> : </v-col>
            <v-col cols="6" align="right"
              ><span>{{
                format_vat_type(data_uninvoice_summary_dict.codVatType)
              }}</span></v-col
            >
          </v-row>
          <v-row class="py-1">
            <v-col cols="6"> <strong>ภาษีค่า COD</strong> : </v-col>
            <v-col cols="6" align="right"
              ><span>{{
                formatMoney(data_uninvoice_summary_dict.codVatAmount)
              }}</span></v-col
            >
          </v-row>

          <v-row class="py-1">
            <v-col cols="6"> <strong>ประเภทภาษีค่าบริการ</strong> : </v-col>
            <v-col cols="6" align="right"
              ><span>{{
                format_vat_type(data_uninvoice_summary_dict.serviceVatType)
              }}</span></v-col
            >
          </v-row>
          <v-row class="py-1">
            <v-col cols="6"> <strong>ภาษีค่าบริการ</strong> : </v-col>
            <v-col cols="6" align="right"
              ><span>{{
                formatMoney(data_uninvoice_summary_dict.serviceVatAmount)
              }}</span></v-col
            >
          </v-row>

          <v-row class="py-1">
            <v-col cols="6"> <strong>ราคารวมทั้งหมด</strong> : </v-col>
            <v-col cols="6" align="right" style="color: red"
              ><span>{{
                formatMoney(data_uninvoice_summary_dict.sum_total)
              }}</span></v-col
            >
          </v-row>
        </v-card-text>
        <v-card-actions class="mx-2">
          <v-spacer />
          <v-btn dark color="primary" class="my-1" @click="createInvoice()"
            ><v-icon class="ma-1">mdi-content-save</v-icon>สร้างใบแจ้งหนี้</v-btn
          >
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { header_token } from "@/website/token";
import Loading from "@/website/components/Loading";
import {
  financialService,
  manageShopService,
  generalService_dotnet,
  reportService_dotnet,
} from "@/website/global";
import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
import {
  FormatInvoice,
  FormatOrder,
  formatMoney,
  isNumberWNoDot,
  set_format_date_time,
  format_price,
  format_vat_type,
} from "@/website/global_function";
export default {
  components: {
    Loading,
  },
  data: () => ({
    page_loading: true,
    loading: false,

    navigete: [],
    resultCount: 0,
    // เปิด เลือกวันที่
    dateStartTextShow: null,
    startDate: null,
    menuStartDate: false,
    checkStart: true,

    dateEndTextShow: null,
    endDate: null,
    menuEndDate: false,
    checkEnd: true,
    pageTotalVisible: 9,
    // ปิด เลือกวันที่

    search_shops: null,
    search_groupName: null,
    PageLimits: 10,
    confirmCreateInvoiceDialog: false,
    tabSelected: null,
    tabList: ["สร้างใบแจ้งหนี้", "ใบแจ้งหนี้ทั้งหมด"],
    shop: {
      headers: [
        { text: "ชื่อร้าน", value: "Name", sortable: false },
        { text: "เวลา/วันที่", value: "SendToExpressDatetime", sortable: false },
        { text: "ออเดอร์", value: "ID", sortable: false },
        { text: "เลขแทรค", value: "TrackingNo", sortable: false },

        { text: "ค่าส่ง", value: "DeliveryPrice", sortable: false, align: "right" },
        {
          text: "ค่าพื้นที่ห่างไกล",
          value: "RemotePrice",
          sortable: false,
          align: "right",
        },
        { text: "ค่า COD", value: "CODCharged", sortable: false, align: "right" },
        { text: "ค่ากล่อง", value: "BoxPrice", sortable: false, align: "right" },
        { text: "ค่าแพค", value: "PackPrice", sortable: false, align: "right" },
        { text: "ส่วนลด", value: "Discount", sortable: false, align: "right" },
        { text: "ราคารวม", value: "TotalPrice", sortable: false, align: "right" },
      ],
      table: [],
      footer_props: {
        "items-per-page-options": [50],
        "items-per-page-text": null,
        "disable-items-per-page": true,
      },
      list: [],
      shop_select: "",
      selected_to_make_invoice: [],
      key_table: 0,
    },
    dataConfirmCreateInvoice: {
      dataList: [],
      sendPriceMakeInvoice: 0,
      remotePriceMakeInvoice: 0,
      codPriceMakeInvoice: 0,
      boxPriceMakeInvoice: 0,
      packPriceMakeInvoice: 0,
      discountMakeInvoice: 0,
    },
    invoice: {
      datetime_search: [
        new Date(new Date().setDate(new Date().getDate() - 1))
          .toISOString()
          .substr(0, 10),
        new Date(new Date().setDate(new Date().getDate())).toISOString().substr(0, 10),
      ],
      showDatetime: false,
      headers: [
        // { text: 'Invoice No', align: 'center', sortable: false, value: 'invoiceNo', width : "150px"},
        { text: "ลำดับ", align: "center", sortable: false, value: "num", width: "50px" },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "เลขอ้างอิง",
          align: "center",
          sortable: false,
          value: "groupName",
          width: "170px",
        },
        {
          text: "ร้านค้า",
          align: "left",
          sortable: false,
          value: "shopName",
          width: "200px",
        },
        {
          text: "ค่าส่ง",
          align: "right",
          sortable: false,
          value: "deliveryPrice",
          width: "80px",
        },
        {
          text: "ค่าพื้นที่ห่างไกล",
          align: "right",
          sortable: false,
          value: "remotePrice",
          width: "90px",
        },
        {
          text: "ค่า COD",
          align: "right",
          sortable: false,
          value: "codPrice",
          width: "90px",
        },
        {
          text: "ภาษีค่า COD",
          align: "right",
          sortable: false,
          value: "codVatAmount",
          width: "90px",
        },
        {
          text: "ภาษีค่า COD %",
          align: "right",
          sortable: false,
          value: "codVatPercent",
          width: "90px",
        },
        {
          text: "ประภทภาษีค่า COD",
          align: "center",
          sortable: false,
          value: "codVatType",
          width: "120px",
        },
        {
          text: "ค่าแพค",
          align: "right",
          sortable: false,
          value: "packPrice",
          width: "90px",
        },
        {
          text: "ค่ากล่อง",
          align: "right",
          sortable: false,
          value: "boxPrice",
          width: "90px",
        },
        {
          text: "ค่าบับเบิล",
          align: "right",
          sortable: false,
          value: "bubblePrice",
          width: "90px",
        },
        {
          text: "ค่าเชื่อมต่อ Vrich",
          align: "right",
          sortable: false,
          value: "vrichFee",
          width: "90px",
        },
        {
          text: "ภาษีค่าบริการ",
          align: "right",
          sortable: false,
          value: "serviceVatAmount",
          width: "90px",
        },
        {
          text: "ภาษีค่าบริการ %",
          align: "right",
          sortable: false,
          value: "serviceVatPercent",
          width: "90px",
        },
        {
          text: "ประเภทภาษีค่าบริการ",
          align: "center",
          sortable: false,
          value: "serviceVatType",
          width: "120px",
        },
        {
          text: "ค่าใช้จ่ายอื่นๆ",
          align: "right",
          sortable: false,
          value: "otherServicePrice",
          width: "90px",
        },
        {
          text: "ส่วนลด",
          align: "right",
          sortable: false,
          value: "discount",
          width: "90px",
        },
        {
          text: "ทั้งหมดรวมภาษีแล้ว",
          align: "right",
          sortable: false,
          value: "totalPriceIncludeVat",
          width: "120px",
        },
        {
          text: "สร้างโดย",
          align: "left",
          sortable: false,
          value: "createdByName",
          width: "100px",
        },
        {
          text: "ยกเลิก",
          align: "center",
          sortable: false,
          value: "CancelInvoice",
          width: "100px",
        },
        // { text: 'ปริ้น', align: 'center', sortable: false, value: 'Print', width : "100px"},
      ],
      key_table: 0,
      table: [],
    },

    headers_group_name_not_null: [
      // { text: 'Invoice No', align: 'center', sortable: false, value: 'invoiceNo', width : "150px"},
      { text: "ลำดับ", align: "center", sortable: false, value: "num", width: "50px" },
      {
        text: "วันที่สร้าง",
        align: "center",
        sortable: false,
        value: "createdDatetime",
        width: "160px",
      },
      {
        text: "เลขอ้างอิง",
        align: "center",
        sortable: false,
        value: "groupName",
        width: "170px",
      },
      {
        text: "ร้านค้า",
        align: "left",
        sortable: false,
        value: "shopName",
        width: "200px",
      },
      {
        text: "ค่าส่ง",
        align: "right",
        sortable: false,
        value: "deliveryPrice",
        width: "90px",
      },
      {
        text: "ค่าพื้นที่ห่างไกล",
        align: "right",
        sortable: false,
        value: "remotePrice",
        width: "90px",
      },
      {
        text: "ค่า COD",
        align: "right",
        sortable: false,
        value: "codPrice",
        width: "90px",
      },
      {
        text: "ภาษีค่า COD",
        align: "right",
        sortable: false,
        value: "codVatAmount",
        width: "90px",
      },
      {
        text: "ภาษีค่า COD %",
        align: "right",
        sortable: false,
        value: "codVatPercent",
        width: "90px",
      },
      {
        text: "ประภทภาษีค่า COD",
        align: "center",
        sortable: false,
        value: "codVatType",
        width: "120px",
      },
      {
        text: "ค่าแพค",
        align: "right",
        sortable: false,
        value: "packPrice",
        width: "90px",
      },
      {
        text: "ค่ากล่อง",
        align: "right",
        sortable: false,
        value: "boxPrice",
        width: "90px",
      },
      {
        text: "ค่าบับเบิล",
        align: "right",
        sortable: false,
        value: "bubblePrice",
        width: "90px",
      },
      {
        text: "ค่าเชื่อมต่อ Vrich",
        align: "right",
        sortable: false,
        value: "vrichFee",
        width: "90px",
      },
      {
        text: "ภาษีค่าบริการ",
        align: "right",
        sortable: false,
        value: "serviceVatAmount",
        width: "90px",
      },
      {
        text: "ภาษีค่าบริการ %",
        align: "right",
        sortable: false,
        value: "serviceVatPercent",
        width: "90px",
      },
      {
        text: "ประเภทภาษีค่าบริการ",
        align: "center",
        sortable: false,
        value: "serviceVatType",
        width: "120px",
      },
      {
        text: "ค่าใช้จ่ายอื่นๆ",
        align: "right",
        sortable: false,
        value: "otherServicePrice",
        width: "90px",
      },
      {
        text: "ส่วนลด",
        align: "right",
        sortable: false,
        value: "discount",
        width: "90px",
      },
      {
        text: "ทั้งหมดรวมภาษีแล้ว",
        align: "right",
        sortable: false,
        value: "totalPriceIncludeVat",
        width: "120px",
      },
      {
        text: "สร้างโดย",
        align: "left",
        sortable: false,
        value: "createdByName",
        width: "100px",
      },
      {
        text: "ยกเลิก",
        align: "center",
        sortable: false,
        value: "CancelInvoice",
        width: "90px",
      },
      // { text: 'ปริ้น', align: 'center', sortable: false, value: 'Print', width : "100px"},
    ],

    listOrderInvoiceDialog: false,

    ShopDetailInvoiceText: null,
    listOrderInvoice: {
      headers: [
        { text: "", align: "center", value: "num", sortable: false },
        { text: "ออเดอร์", align: "center", value: "orderID", sortable: false },
        { text: "เลขแทรค", align: "center", value: "trackingNo", sortable: false },
        { text: "ขนส่ง", align: "center", value: "expressCompanyLogo", sortable: false },
        // { text: 'ขนส่ง', align: 'center', value: 'expressCompanyName', sortable: false },
        { text: "ค่าส่ง", align: "right", value: "deliveryPrice", sortable: false },
        {
          text: "ค่าพื้นที่ห่างไกล",
          align: "right",
          value: "remotePrice",
          sortable: false,
        },
        { text: "ค่า COD", align: "right", value: "codPrice", sortable: false },
        { text: "ค่ากล่อง", align: "right", value: "boxPrice", sortable: false },
        { text: "ค่าแพค", align: "right", value: "packPrice", sortable: false },
        { text: "ค่าบับเบิล", align: "right", value: "bubblePrice", sortable: false },
        { text: "ส่วนลด", value: "discount", sortable: false, align: "right" },
        { text: "ค่าเชื่อต่อ Vrich", value: "vrichFee", sortable: false, align: "right" },
        { text: "ราคารวม", align: "right", value: "totalPrice", sortable: false },
      ],
      key_table: 0,
      table: [],
      invoiceId: "",
    },

    ch_InvoiceGroupID: null,
    item_invoice_list: [],
    headers_item: [
      { text: "รายละเอียด", align: "left", value: "description", sortable: false },
      { text: "จำนวน", align: "right", value: "quantity", sortable: false },
      { text: "ราคาต่อหน่วย", align: "right", value: "unitPrice", sortable: false },
      { text: "รวม", align: "right", value: "totalPrice", sortable: false },
    ],
    // paginations
    page: 1,
    pageTemp: 1,

    row_per_page: 50,
    pageLength: null,

    row_per_page_select: 50,
    row_per_page_select_tmp: 50,
    row_per_page_list: [
      { text: "25", value: 25 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: "200", value: 200 },
      { text: "500", value: 500 },
      { text: "ทั้งหมด", value: 10000000 },
    ],

    IsDataInvoice: null,
    reGetInvoice: 1,

    // ข้อมูล dialog invoice

    invoice_id_row: null,
    shopDisibled: true,
    branchDisibled: true,
    IsReadonly: false,
    ShopAddressType: null,
    BranchAddressType: null,
    dialog_invoice_invoiceID: null,
    dialog_invoice_ShopID: null,
    dialog_invoice_BranchID: null,

    // checkbox for data table
    checkboxList: [],
    checkboxAll: [],
    // selected: [],

    // ออกใบกำกับภาษี shop
    Shop_FullAddress_text: null,
    ShopAddressInvoice: {
      ID: null,
      Name: null,
      Phone: null,
      IDCardNumber: null,
      TextAddress: null,
      Address: null,
      PostcodeMappingID: null,
      IsDefault: true,
    },

    ShopAddressInvoice_tmp: {
      ID: null,
      Name: null,
      Phone: null,
      IDCardNumber: null,
      TextAddress: null,
      Address: null,
      PostcodeMappingID: null,
      IsDefault: true,
    },

    // ออกใบกำกับภาษี branch
    branch_FullAddress_text: null,
    BranchAddressInvoice: {
      ID: null,
      Name: null,
      Phone: null,
      IDCardNumber: null,
      TextAddress: null,
      Address: null,
      PostcodeMappingID: null,
      IsDefault: true,
    },

    BranchAddressInvoice_tmp: {
      ID: null,
      Name: null,
      Phone: null,
      IDCardNumber: null,
      TextAddress: null,
      Address: null,
      PostcodeMappingID: null,
      IsDefault: true,
    },

    //export to excel
    excel_invoice_list_header: [
      { label: "Invoice No", field: "invoiceNo", width: 15 },
      {
        label: "วันที่สร้าง",
        field: "createdDatetime",
        width: 20,
        dataFormat: set_format_date_time,
      },
      {
        label: "วันที่ใบแจ้งหนี้",
        field: "invoiceDate",
        width: 20,
        dataFormat: set_format_date_time,
      },
      { label: "ร้านค้า", field: "shopName", width: 40 },
      { label: "ภาษี", field: "vatType", width: 15, dataFormat: format_vat_type },
      { label: "ยอดรวม", field: "totalItemPrice", width: 15 },
      { label: "ยอดที่คำนวนภาษี", field: "calculatedPrice", width: 15 },
      { label: "ภาษีมูลค่าเพิ่ม", field: "vatAmount", width: 15 },
      { label: "ยอดรวมทั้งหมด", field: "totalPrice", width: 15 },
      { label: "สร้างโดย", field: "createdByName", width: 30 },
    ],

    excel_detail_invoice_list_header: [
      { label: "ออเดอร์", field: "orderID", width: 15 },
      { label: "เลขแทรค", field: "trackingNo", width: 25 },
      { label: "ขนส่ง", field: "expressCompanyName", width: 25 },
      { label: "น้ำหนัก", field: "boxWeight", width: 15 },
      { label: "กว้าง", field: "boxWeight", width: 15 },
      { label: "ยาว", field: "boxLong", width: 15 },
      { label: "สูง", field: "boxHigh", width: 15 },
      { label: "เลขอ้างอิง", field: "referenceNo", width: 30 },
      { label: "ค่าส่ง", field: "deliveryPrice", width: 15 },
      { label: "ค่าพื้นที่ห่างไกล", field: "remotePrice", width: 15 },
      { label: "ค่า COD", field: "codPrice", width: 15 },
      { label: "ค่ากล่อง", field: "boxPrice", width: 15 },
      { label: "ค่าแพค", field: "packPrice", width: 15 },
      { label: "ค่าบับเบิล", field: "bubblePrice", width: 15 },
      { label: "ส่วนลด", field: "discount", width: 15 },
      { label: "ค่าเชื่อต่อ Vrich", field: "vrichFee", width: 15 },
      { label: "ราคารวม", field: "totalPrice", width: 15 },
    ],

    dialog_select_print: false,
    select_print_type: 1,
    item_for_print: null,

    data_group_detail: null,
    data_group_detailt_for_excel: [],
    data_uninvoice_summary_dict: null,
    rd_status: "2",
    rd_status_tmp: "2",

    // select
    selected: [],
  }),
  async created() {
    this.navigete = JSON.parse(localStorage.getItem("navigete"));
    await this.get_shop_all();
    this.shop["shop_select"] = this.shop["list"][0];
    this.getInvoice(this.PageLimits, 0, 1);
    this.page_loading = false;
  },

  watch: {
    rd_status_tmp: async function () {
      this.selected = [];
    },
    selected: async function (val) {
      for (var i = 0; i < val.length; i++) {
        if (val[i].groupName != null) {
          var index = this.selected.indexOf(val[i]);
          this.selected.splice(index, 1);
        }
      }

      // if(val.groupName != null){
      //
      // }
      // this.loading = true
      // if(this.search_shops == null || this.search_shops.ID == null){
      // this.AlertWarning("เลือกร้านค้า")
      //   this.selected = []
      //   this.loading = false
      // }
      // this.loading = false
    },
  },
  methods: {
    async get_shop_all() {
      let response = await axios.post(
        financialService + "financialFC/getShop",
        {
          BranchID: localStorage.getItem("Branch_BranchID"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      this.loading = false;
      if (response.data.status == "Success") {
        this.shop["list"] = response.data.result;
        this.shop["key_table"]++;
      }
    },

    allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),

    set_row_per_page() {
      this.row_per_page_select = parseInt(this.row_per_page_select_tmp);
    },

    async getInvoice(limits, offsets, page) {
      // this.loading = true
      if (this.startDate != null && this.endDate != null) {
        this.date_range = [this.startDate, this.endDate];
      } else {
        this.date_range = [];
      }

      this.loading = true;

      // let res1 = await axios.post(reportService_dotnet+'Invoice/invoice-list', {
      //   "branchID": localStorage.getItem('Branch_BranchID'),
      //   "shopID": this.search_shops != null?this.search_shops['ID']:null,
      //   "dateFrom": this.startDate,
      //   "dateTo": this.endDate,
      //   "skip": 0,
      //   "take": 1000000
      // },
      // { headers: header_token})
      //     const dateFrom_tmp = res1.data.dateFrom.split("-")
      //     const dateTo_tmp = res1.data.dateTo.split("-")
      //     this.startDate = dateFrom_tmp[2] + '-' + dateFrom_tmp[1] + '-' + dateFrom_tmp[0]
      //     this.endDate = dateTo_tmp[2] + '-' + dateTo_tmp[1] + '-' + dateTo_tmp[0]
      //     this.invoice['table'] = []
      //     this.invoice['table'] = res1.data.invoiceList
      this.rd_status_tmp = this.rd_status;
      var status = null;
      if (this.rd_status_tmp == "null") {
        status = null;
      } else if (this.rd_status_tmp == "1") {
        status = 1;
      } else if (this.rd_status_tmp == "2") {
        status = 2;
      }

      if (this.search_groupName == "") {
        this.search_groupName = null;
      }

      let res2 = await axios.post(
        reportService_dotnet + "Invoice/invoice-list-for-group",
        {
          branchID: localStorage.getItem("Branch_BranchID"),
          shopID: this.search_shops, // this.search_shops != null?this.search_shops['ID']:null
          groupName: this.search_groupName,
          dateFrom: this.startDate,
          dateTo: this.endDate,
          status: status,
          skip: offsets,
          take: limits,
        },
        { headers: header_token }
      );
      this.startDate = res2.data.dateFrom;
      this.endDate = res2.data.dateTo;

      this.invoice["table"] = [];
      this.invoice["table"] = res2.data.invoiceList;

      this.invoice["showDatetime"] = false;
      this.loading = false;
      this.page = page;
      this.pageTemp = page;
      this.resultCount = res2.data.resultCount;
      this.pageLength = Math.ceil(res2.data.resultCount / this.PageLimits);
      if (this.pageLength <= 0) {
        this.pageLength = 1;
      }
    },
    confirmCreateInvoice() {
      var shop_tmp = this.selected[0].shopID;
      for (var i2 = 0; i2 < this.selected.length; i2++) {
        if (shop_tmp != this.selected[i2].shopID && this.selected[i2].groupName == null) {
          this.AlertWarning("กรุณาเลือกร้านค้าเดียวกัน");
          Swal.fire({
            icon: "warning",
            title: "ไม่สามารถสร้างบิลได้",
            text: "กรุณาเลือกสร้างบิลจากร้านค้าเดียวกัน",
          });
          return;
        }
      }

      this.data_uninvoice_summary_dict = {};
      this.data_uninvoice_summary_dict.countOrder = 0;
      this.data_uninvoice_summary_dict.date = 0;
      this.data_uninvoice_summary_dict.shopName = this.search_shops;
      this.data_uninvoice_summary_dict.deliveryPrice = 0;
      this.data_uninvoice_summary_dict.remotePrice = 0;
      this.data_uninvoice_summary_dict.codPrice = 0;
      this.data_uninvoice_summary_dict.boxPrice = 0;
      this.data_uninvoice_summary_dict.packPrice = 0;
      this.data_uninvoice_summary_dict.bubblePrice = 0;
      this.data_uninvoice_summary_dict.vrichFee = 0;
      this.data_uninvoice_summary_dict.discount = 0;
      this.data_uninvoice_summary_dict.totalPrice = 0;
      this.data_uninvoice_summary_dict.otherServicePrice = 0;

      this.data_uninvoice_summary_dict.codVatAmount = 0;
      this.data_uninvoice_summary_dict.codVatType = this.selected[0].codVatType;

      this.data_uninvoice_summary_dict.serviceVatAmount = 0;
      this.data_uninvoice_summary_dict.serviceVatType = this.selected[0].serviceVatType;

      this.data_uninvoice_summary_dict.sum_total = 0;

      for (var i = 0; i < this.selected.length; i++) {
        // this.data_uninvoice_summary_dict.countOrder += this.selected[i].xx
        // this.data_uninvoice_summary_dict.date += this.selected[i].xx
        this.data_uninvoice_summary_dict.deliveryPrice += this.selected[i].deliveryPrice;
        this.data_uninvoice_summary_dict.remotePrice += this.selected[i].remotePrice;
        this.data_uninvoice_summary_dict.codPrice += this.selected[i].codPrice;
        this.data_uninvoice_summary_dict.boxPrice += this.selected[i].boxPrice;
        this.data_uninvoice_summary_dict.packPrice += this.selected[i].packPrice;
        this.data_uninvoice_summary_dict.bubblePrice += this.selected[i].bubblePrice;
        this.data_uninvoice_summary_dict.vrichFee += this.selected[i].vrichFee;
        this.data_uninvoice_summary_dict.discount += this.selected[i].discount;
        this.data_uninvoice_summary_dict.otherServicePrice += this.selected[
          i
        ].otherServicePrice;
        this.data_uninvoice_summary_dict.totalPrice +=
          this.selected[i].deliveryPrice +
          this.selected[i].remotePrice +
          this.selected[i].codPrice +
          this.selected[i].boxPrice +
          this.selected[i].packPrice +
          this.selected[i].bubblePrice +
          this.selected[i].vrichFee +
          this.selected[i].otherServicePrice -
          this.selected[i].discount;

        this.data_uninvoice_summary_dict.codVatAmount += this.selected[i].codVatAmount;
        this.data_uninvoice_summary_dict.serviceVatAmount += this.selected[
          i
        ].serviceVatAmount;
      }

      this.data_uninvoice_summary_dict.sum_total =
        this.data_uninvoice_summary_dict.totalPrice +
        this.data_uninvoice_summary_dict.serviceVatAmount;
      if (this.data_uninvoice_summary_dict.codVatType == 2) {
        this.data_uninvoice_summary_dict.totalPrice += this.data_uninvoice_summary_dict.codVatAmount;
      } else if (this.data_uninvoice_summary_dict.codVatType == 3) {
        this.data_uninvoice_summary_dict.totalPrice -= this.data_uninvoice_summary_dict.codVatAmount;
      }

      // if(this.data_uninvoice_summary_dict.serviceVatType == 2){
      //   this.data_uninvoice_summary_dict.sum_total += this.data_uninvoice_summary_dict.serviceVatAmount
      // } else if (this.data_uninvoice_summary_dict.serviceVatType == 3) {
      //   this.data_uninvoice_summary_dict.sum_total -= this.data_uninvoice_summary_dict.serviceVatAmount
      // }

      if (this.data_uninvoice_summary_dict.sum_total > 0) {
        this.confirmCreateInvoiceDialog = true;
      } else {
        this.AlertWarning("ยอดเงินเป็น 0");
      }
    },

    async createInvoice() {
      try {
        var group_id_list = [];

        for (var i = 0; i < this.selected.length; i++) {
          group_id_list.push(this.selected[i].id);
        }

        this.loading = true;
        let res = await axios.post(
          reportService_dotnet + "Invoice/create-group-name-invoice-by-group-id",
          {
            // "branchID" : localStorage.getItem('Branch_BranchID') ,
            // "shopID" : this.search_shops != null?this.search_shops['ID']:null ,
            createdBy: localStorage.getItem("Branch_StaffID"),
            groupIDList: group_id_list,
          },
          { headers: header_token }
        );
        this.confirmCreateInvoiceDialog = false;
        this.selected = [];
        this.loading = false;

        this.getInvoice(this.PageLimits, 0, 1);
      } catch (e) {
        this.AlertError();
        this.loading = false;
      }
    },

    async removeInvoice(item) {
      this.$swal({
        type: "warning",
        title: "ต้องการลบใบแจ้งหนี้้ใช่หรือไม่ !!",
        // text: this.myKeyword.textD1[this.language]+' '+ name +' '+ this.myKeyword.textD2[this.language],
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          var data_remove = [];
          if (item == null) {
            for (var i = 0; i < this.selected.length; i++) {
              data_remove.push(this.selected[i].id);
            }
          } else {
            data_remove.push(item.id);
          }

          if (data_remove.length > 0) {
            this.loading = true;
            let res = await axios.post(
              reportService_dotnet + "Invoice/delete-invoice-many-id",
              {
                groupInvoiceID_List: data_remove,
              },
              { headers: header_token }
            );
            this.loading = false;
            this.getInvoice(this.PageLimits, 0, 1);
            this.AlertSuccess();
          }
        }
      });
    },
    async LimitChange() {
      await this.getInvoice(this.PageLimits, 0, 1);
    },
    async changePage() {
      if (this.pageLength != 1 && this.pageTemp != this.page) {
        var offsets = this.page * this.PageLimits - this.PageLimits;
        await this.getInvoice(this.PageLimits, offsets, this.page);
        this.pageTemp = this.page;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");

      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    FormatInvoice,
    FormatOrder,
    formatMoney,
    isNumberWNoDot,
    set_format_date_time,
    format_price,
    format_vat_type,
    AlertSuccess,
    AlertWarning,
    AlertError,
  },
};
</script>

<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}

.mobile-none {
  display: ;
}

.pc-none {
  display: none;
}

@media (max-width: 599px) {
  .mobile-none {
    display: none;
  }

  .pc-none {
    display: ;
  }
}
</style>
